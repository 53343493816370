// 质检记录
<template>
  <div class="menu">
    <div class="screening">
      <el-dialog title="查询" v-model="theQueryDialog" :close-on-click-modal="false" :destroy-on-close="true"
        width="600px">
        <el-form label-position="left" label-width="100px" size="small" :model="searchFrom" ref="upform">
          <el-form-item label="主叫号码">
            <el-input v-model="searchFrom.telA" class="input-searchFrom-width" placeholder="请输入需要查询的手机号" size="mini">
            </el-input>
          </el-form-item>
          <el-form-item label="企业号码">
            <el-input v-model="searchFrom.telX" class="input-searchFrom-width" placeholder="请输入需要查询的手机号" size="mini">
            </el-input>
          </el-form-item>
          <el-form-item label="被叫号码">
            <el-input v-model="searchFrom.telB" class="input-searchFrom-width" placeholder="请输入需要查询的手机号" size="mini">
            </el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker v-model="valueTime" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期"
              clearable value-format="YYYY-MM-DD HH:mm:ss" size="mini" :default-time="defaultTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="风险等级">
            <div class="input-searchFrom-width">
              <el-select v-model="search_risk" placeholder="请选择风险等级" style="width: 100%" clearable filterable>
                <el-option
                  v-for="(item,index) in [{label:'全部',value:''},{label:'高',value:3},{label:'中',value:2},{label:'低',value:1},{label:'无',value:0}]"
                  :key="index" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="命中关键词">
            <el-input v-model="searchFrom.keyword" class="input-searchFrom-width" placeholder="请输入需要查询的命中关键词"
              size="mini"></el-input>
          </el-form-item>
          <el-form-item label="质检类型">
            <div class="input-searchFrom-width">
              <el-select v-model="searchFrom.hitType" placeholder="请选择质检类型" style="width: 100%" clearable filterable>
                <el-option v-for="(item, index) in voiceName" :key="index" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <!--          <el-form-item label="用户">
            <div class="input-searchFrom-width">
              <el-select v-model="searchFrom.uid" placeholder="请选择用户" style="width: 100%" clearable filterable>
                <el-option v-for="(item, index) in user_list" :key="index" :label="`${item.nickname}(Id:${item.uid})`"
                  :value="item.uid">
                  <div class="flex-nowrap-space-between">
                    <div :class="item.status == 1?'no-red':''">
                      <span>{{ item.nickname }}</span>
                      <span class="ml-5">(Id:{{ item.uid }})</span>
                    </div>
                    <div v-if="item.status == 1" class="no-red">禁</div>
                  </div>
                </el-option>
              </el-select>
            </div>
          </el-form-item>-->
          <el-form-item label="服务商">
            <el-select class="input-searchFrom-width" v-model="searchFrom.amountId" placeholder="请选择查询的服务商"
              style="width: 100%" @change="changeValuesamountId">
              <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="接口类型">
            <el-select class="input-searchFrom-width" v-model="searchFrom.interfaceId" placeholder="请选择查询的接口类型"
              style="width: 100%" clearable filterable>
              <el-option v-for="(item, index) in interface_id" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="通话时间">
            <div class="input-searchFrom-width">
              <el-select v-model="durationVal" placeholder="请选择通话时间" style="width: 100%" @change="changeValues"
                clearable filterable>
                <el-option v-for="(item, index) in [
                                    {
                                        name:'所有时间',
                                        val:0
                                    },{
                                        name:'0~10秒',
                                        val:1
                                    },{
                                        name:'10~60秒',
                                        val:2
                                    },{
                                        name:'60~90秒',
                                        val:3
                                    },{
                                        name:'90~120秒',
                                        val:4
                                    },{
                                        name:'大于120秒',
                                        val:5
                                    },
                                ]" :key="index" :label="item.name" :value="item.val">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button class="cancel" plain @click="reset(1)" round>
                重置
              </el-button>
              <el-button class="confirmAdd" @click="onSubmit" round>
                查询
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          数据列表
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <!-- <div class="title-name">
					数据列表
				</div> -->
        <div class="risk-button">
          <span>风险等级：</span>
          <el-radio-group @change="getDataList(true)" fill="#637DFF" v-model="searchFrom.risk">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button :label="3">高</el-radio-button>
            <el-radio-button :label="2">中</el-radio-button>
            <el-radio-button :label="1">低</el-radio-button>
            <el-radio-button :label="0">无</el-radio-button>
          </el-radio-group>
        </div>
      </el-col>
      <!--      <el-col :span="8">
        <div style="width:100%;height:100%;">
          <div style="display:flex;flex-wrap:wrap;justify-content:space-evenly;">
            <div style="font-size:12px;flex: 0 0 45%;display:flex;align-items:center;" v-if="progressData.non">
              无<el-progress style="width:100%;margin-left:10px;" :text-inside="true" :stroke-width="14" status="success"
                :percentage="progressData.non.precent||0" :format="progressData.non.format"></el-progress>
            </div>
            <div style="font-size:12px;flex: 0 0 45%;display:flex;align-items:center;" v-if="progressData.low">
              低<el-progress style="width:100%;margin-left:10px;" :text-inside="true" :stroke-width="14"
                :percentage="progressData.low.precent||0" :format="progressData.low.format"></el-progress>
            </div>
            <div style="font-size:12px;flex: 0 0 45%;display:flex;align-items:center;margin-top:10px;"
              v-if="progressData.mid">
              中<el-progress style="width:100%;margin-left:10px;" :text-inside="true" :stroke-width="14" status="warning"
                :percentage="progressData.mid.precent||0" :format="progressData.mid.format"></el-progress>
            </div>
            <div style="font-size:12px;flex: 0 0 45%;display:flex;align-items:center;margin-top:10px;"
              v-if="progressData.high">
              高<el-progress style="width:100%;margin-left:10px;" :text-inside="true" :stroke-width="14"
                status="exception" :percentage="progressData.high.precent||0" :format="progressData.high.format">
              </el-progress>
            </div>
          </div>
        </div>
      </el-col>-->
      <el-col :span="16">
        <div class="title-buttom">
          <div class="ml-10">
            <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询
            </el-button>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="reset(1)">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh" />
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
          <!-- <el-tooltip content="刷新列表" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-refresh-right" @click="reset" circle></el-button>
                    </el-tooltip> -->
          <!-- <el-tooltip content="新增菜单" placement="top" effect="light">
                        <el-button class="editor" size="mini" icon="el-icon-plus" @click="addMenu" circle></el-button>
                    </el-tooltip> -->
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div>
          <el-table class="eltable" v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark"
            style="width: 100%" :header-cell-style="{background:'#e0e5ff',color:'#24252F'}" row-key="id" size="mini"
            :row-style="{height:'50px'}" :cell-style="{padding:'0px'}" default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}" @selection-change="handleSelectionChange">
            <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
            <el-table-column label="服务商" width="150">
              <template #default="scope">{{ amountName(scope.row.amountId) }}</template>
            </el-table-column>
            <!--            <el-table-column label="用户" width="100">
              <template
                #default="scope">{{ scope.row.nickName == null|| scope.row.nickName == ''?'-': scope.row.nickName}}</template>
            </el-table-column>-->
            <el-table-column label="呼叫号码(主叫/小号/被叫)" width="200">
              <template #default="scope">
                <span><b>主叫:</b> {{ scope.row.telA == null|| scope.row.telA == ''?'-': scope.row.telA}}</span>
                <!-- <el-tooltip content="已被封禁" placement="top" effect="light">
                                <span class="ml-10 pointer"><i class="iconfont icon-fengjin no-red"></i></span>
                            </el-tooltip> -->
                <!-- <el-tooltip content="封禁用户" placement="top" effect="light"> -->
                <!-- 111-->
                <span v-if="scope.row.telADisabled == 0" @click="bannedUser(scope.row)"
                  class="ml-10 pointer yes-green">未禁用</span>
                <span v-else class="ml-10 default-color">已禁用</span>
                <br>
                <span><b>小号:</b> {{ scope.row.telX == null|| scope.row.telX == ''?'-': scope.row.telX}}</span>
                <br>
                <span><b>被叫:</b> {{ scope.row.telB == null|| scope.row.telB == ''?'-': scope.row.telB}}</span>
                <span v-if="scope.row.telBDisabled == 0" @click="bannedUserOther(scope.row)"
                  class="ml-10 pointer yes-green">未禁用</span>
                <span v-else class="ml-10 default-color">已禁用</span>
                <!-- </el-tooltip> -->
              </template>
            </el-table-column>
            <el-table-column label="通话时长" width="80">
              <template
                #default="scope">{{ scope.row.duration == null|| scope.row.duration == ''?'-': scope.row.duration}}秒</template>
            </el-table-column>
            <el-table-column label="风险等级" width="80">
              <template #default="scope">
                <div class="riskText"
                  :class="scope.row.risk === 0 ? 'riskText-nothing' : scope.row.risk === 1 ? 'riskText-low' : scope.row.risk === 2 ? 'riskText-middle' : scope.row.risk === 3 ?'riskText-high' :''">
                  {{ scope.row.riskText == null|| scope.row.riskText == ''?'-': scope.row.riskText}}</div>
              </template>
            </el-table-column>
            <el-table-column label="命中类型/关键字" width="180">
              <template #default="scope">
                <el-tooltip placement="top">
                  <template #content>
                    <p class="ellipsis">
                      <b>类型:</b> {{ scope.row.hitType == null|| scope.row.hitType == ''?'-': scope.row.hitType}}
                    </p>
                    <p class="ellipsis">
                      <b>关键字:</b>
                      {{ scope.row.hitTypeDesc == null|| scope.row.hitTypeDesc == ''?'-': scope.row.hitTypeDesc}}
                    </p>
                  </template>
                  <div>
                    <p class="ellipsis">
                      <b>类型:</b> {{ scope.row.hitType == null|| scope.row.hitType == ''?'-': scope.row.hitType}}
                    </p>
                    <p class="ellipsis">
                      <b>关键字:</b>
                      {{ scope.row.hitTypeDesc == null|| scope.row.hitTypeDesc == ''?'-': scope.row.hitTypeDesc}}
                    </p>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="话术/语音内容">
              <template #default="scope">
                <el-popover placement="left" :width="500" trigger="hover" effect="dark">
                  <template #reference>
                    <p class="ellipsis-line3" @click="quickConfirm(scope.row)" v-html="scope.row.desc.join('')" />
                  </template>
                  <el-row class="voice-text">
                    <el-col :span="8" v-if="scope.row.hitTypeDesc && scope.row.hitTypeDesc !== '-'">
                      <el-tag size="small" effect="light" round v-for="(item, i) in scope.row.hitTypeDesc.split(',')"
                        :key="i" class="keywords" @click="scrollTo($event)">
                        {{ item }}
                      </el-tag>
                    </el-col>
                    <el-col :span="16">
                      <el-scrollbar @mouseenter="quickConfirm(scope.row)" class="voice-text-tooltip" max-height="300px">
                        <p v-for="(item, i) in scope.row.desc" :key="i">
                          <span class="sentence" v-html="item" />
                        </p>
                      </el-scrollbar>
                    </el-col>
                  </el-row>
                </el-popover>
                <p v-if="!scope.row.translatedContent">-</p>
              </template>
            </el-table-column>
            <el-table-column label="准入行业" width="100" fixed="right">
              <template #default="scope">{{ ( scope.row.businessType != null && industryList.length != 0 ) ? industryList.find((item) => item.id ==  scope.row.businessType).name : '--' }}</template>
            </el-table-column>
            <!--<el-table-column label="状态">
              <template
                #default="scope">{{ scope.row.hitTypeStatus == null|| scope.row.hitTypeStatus == ''?'-':(scope.row.hitTypeStatus == 0?'未命中':'命中')}}</template>
            </el-table-column>
            <el-table-column label="备注" width="200">
              <template #default="scope">
                <div class="showRemark">
                  {{ scope.row.remark == null || scope.row.remark == '' ?'-': scope.row.remark}}
                </div>
              </template>
            </el-table-column>-->
            <el-table-column label="质检日期" width="90" fixed="right">
              <template
                #default="scope">{{ scope.row.createTime == null|| scope.row.createTime == ''?'-': scope.row.createTime}}</template>
            </el-table-column>
            <el-table-column label="查看话单" width="100" fixed="right">
              <template #default="scope">
                <span class="button-font-color" @click="openCallList(scope.row)">
                  <icon-font type="icon-chakan" />
                  查看话单
                </span>
              </template>
            </el-table-column>
            <el-table-column label="对话" width="60" fixed="right">
              <template #default="scope">
                <el-tag :class="(scope.row.readInfor === false || scope.row.readInfor == null) &&
                scope.row.read === 0 ? 'unRead' : 'Read'" class="talks" @click="openDiv(scope.row)">
                  <icon-font type="icon-duihua" class="talkcolor" />
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <div class="pager">
      <div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          background :page-sizes="[10, 20, 30, 40]" :page-size="searchFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 话单详情 -->
    <el-dialog v-model="callDialogVisible" title="话单详情" :destroy-on-close="true">
      <el-table class="eltable" v-loading="callLoading" ref="multipleTable" :data="callTableData" tooltip-effect="dark"
        style="width: 100%" :header-cell-style="{background:'#e0e5ff',color:'#24252F'}" row-key="id" size="mini"
        :row-style="{height:'50px'}" :cell-style="{padding:'0px'}" default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <!-- <el-table-column label="用户名" fixed width="110px">
                    <template #default="scope">{{
                        scope.row.nickName == null ? "-" : scope.row.nickName
                    }}</template>
                    </el-table-column> -->
        <!-- <el-table-column label="上游绑定ID" width="170px">
                    <template #default="scope">{{
                        scope.row.subId == null ? "-" : scope.row.subId
                    }}</template>
                    </el-table-column>
                    <el-table-column label="通话ID" width="140px">
                    <template #default="scope">{{
                        scope.row.callId == null ? "-" : scope.row.callId
                    }}</template>
                    </el-table-column>
                    <el-table-column label="隐私号ID" width="160px">
                    <template #default="scope">{{
                        scope.row.numberId == null ? "-" : scope.row.numberId
                    }}</template>
                    </el-table-column> -->
        <el-table-column label="话单消费" width="110px">
          <template #default="scope">{{
                        scope.row.price == null || scope.row.price == 0
                        ? "-"
                        : scope.row.price
                    }}</template>
        </el-table-column>
        <el-table-column label="主叫号码" width="130px">
          <template #default="scope">{{
                        scope.row.telA == null ? "-" : scope.row.telA
                    }}</template>
        </el-table-column>
        <el-table-column label="企业号" width="130px">
          <template #default="scope">{{
                        scope.row.telX == null ? "-" : scope.row.telX
                    }}</template>
        </el-table-column>
        <el-table-column label="被叫号码" width="130px">
          <template #default="scope">{{
                        scope.row.telB == null ? "-" : scope.row.telB
                    }}</template>
        </el-table-column>
        <el-table-column label="通话时间" width="200px">
          <template #default="scope">
            <div class="grid">
              <div>
                开始：{{
                            scope.row.startTime == null ? "-" : scope.row.startTime
                            }}
              </div>
              <div>
                结束：{{
                            scope.row.endTime == null ? "-" : scope.row.endTime
                            }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="通话时长(秒)" width="140px">
          <template #default="scope">{{
                        scope.row.duration == null || scope.row.duration == 0
                        ? "-"
                        : scope.row.duration
                    }}</template>
        </el-table-column>
        <el-table-column label="通话状态" width="110px">
          <template #default="scope">
            <span :class="scope.row.callStatus == 0 ? 'no-red' : 'yes-green'">{{
                            scope.row.callStatus == null
                            ? "-"
                            : scope.row.callStatusText
                        }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结束状态" width="110px">
          <template #default="scope">{{
                        scope.row.finishStatus == null || scope.row.finishStatus == 4
                        ? "-"
                        : scope.row.finishStatusText
                    }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 对话详情 -->
    <el-dialog v-model="centerDialogVisible" top="3vh" width="60%" title="对话详情" :show-close="false"
      :destroy-on-close="true">
      <!-- 1.主体 -->
      <div class="flex-nowrap-flex-start height-min">
        <!-- 1.1左边列表 -->
        <div class="mr-15">
          <!-- <div class="flex-nowrap-flex-start">
            <div class="left-title">主叫所属用户：</div>
            <div class="left-text">{{ rowJson.nickName }}</div>
          </div> -->
          <div class="flex-nowrap-flex-start">
            <div class="left-title">主叫：</div>
            <div class="left-text flex-nowrap-space-between">
              <div>{{ rowJson.telA }}</div>
              <el-button class="mr-10" type="danger" size="mini" v-if="rowJson.telADisabled == 0"
                @click="bannedUser(rowJson)">禁用</el-button>
              <el-tag class="mr-10" type="danger" v-else>已禁用</el-tag>
            </div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title">被叫：</div>
            <div class="left-text flex-nowrap-space-between">
              <div>{{ rowJson.telB }}</div>
              <el-button class="mr-10" type="danger" size="mini" v-if="rowJson.telBDisabled == 0"
                @click="bannedUserOther(rowJson)">禁用</el-button>
              <el-tag class="mr-10" type="danger" v-else>已禁用</el-tag>
            </div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title">企业号码：</div>
            <div class="left-text">{{ rowJson.telX }}</div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title">通话时长：</div>
            <div class="left-text">{{ rowJson.duration }}秒</div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title">命中类型：</div>
            <div class="left-text">{{ rowJson.hitType }}</div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title-other">命中关键字：</div>
            <div class="left-text-other overflow-y">
              <el-tag class="right-width" v-for="(item,i) in hitTypeDescList" :key="i">{{ item }}</el-tag>
            </div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title">报备行业：</div>
            <div class="left-text">{{ rowJson.industry}}</div>
          </div>
          <div class="flex-nowrap-flex-start">
            <div class="left-title-other">报备话术：</div>
            <div class="left-text-other overflow-y">
              {{ rowJson.textarea1641434070934 }}
            </div>
          </div>
          <div class="bottom-box flex-nowrap-center-center">
            <audio class="recordSon-audio" :src="rowJson.recordUrl" controls="controls"></audio>
          </div>
        </div>
        <!-- 1.2右边对话 -->
        <div class="talkBox">
          <div v-for="(oitem,o) in rowJson.translatedContent" :key="o">
            <div class="talkBox-item" v-show="oitem.channel_id == 0">
              <div class="text-align-left">主叫</div>
              <div class="flex-nowrap-flex-start relative-box">
                <div>
                  <el-avatar :size="40" :src="require(`@/assets/images/telA.png`)"></el-avatar>
                </div>
                <div class="triangle"></div>
                <div class="ml-15 telA-box" v-html="$filter.mateKeyword(oitem?.text,hitTypeDescList)"></div>
              </div>
            </div>
            <div class="talkBox-item" v-show="oitem.channel_id == 1">
              <div class="text-align-right">被叫</div>
              <div class="flex-nowrap-flex-end relative-box">
                <div class="mr-15 telB-box" v-html="$filter.mateKeyword(oitem?.text,hitTypeDescList)"></div>
                <div class="triangle-other"></div>
                <div>
                  <el-avatar :size="40" :src="require(`@/assets/images/telB.png`)"></el-avatar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 2.底部操作 -->
      <div class="flex-nowrap-space-between mt-10">
        <el-button type="primary" plain @click="copyMessage">复制质检记录</el-button>
        <div>
          <el-button type="primary" plain @click="next">下一个</el-button>
          <el-button @click="centerDialogVisible = false">关闭</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import voiceCheck from '@/api/open/voiceCheck/voiceCheck'
import bill from '@/api/open/privacy/bill'
import voiceType from '@/api/open/voice/voiceType'
import { industryQuery } from '@/api/open/industry/query'
import userts from '@/api/web/user'
import interfacets from '@/api/open/privacy/interface'
import amountts from '@/api/open/privacy/amount'
import blackNumber from '@/api/open/black/blackNumber'
import talkDetails from '@/components/talkDetails'
import { getDate } from '@/utils/timeToChoose.js'
import { IconFont } from '@/utils/iconfont'
import { ElMessage } from 'element-plus'
import { watch } from 'vue'
export default {
  components: {
    IconFont,
    talkDetails,
  },
  data() {
    return {
      industryList: [],
      hitTypeDescList: [],
      readId: [],
      callDialogVisible: false,
      centerDialogVisible: false,
      searchFrom: {
        page: 1,
        pageSize: 10,
        telA: '',
        telB: '',
        amountId: 74,
        interfaceId: '',
        risk: '', //风险等级(0-无/1-低/2-中/3-高)
      },
      durationVal: 0,
      durationList: [],
      total: 0,
      currentPage: 1, //分页
      multipleSelection: [], //选中集合
      valueTime: [], //选中时间段
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ], //默认时间 '12:00:00', '08:00:00'
      activeNames: [], //折叠面板
      tableData: [], //菜单表
      // loding 状态启用
      loading: true,
      callLoading: true,
      callTableData: [],
      dialogueList: [],
      rowJson: {},
      recordUrl: '',
      hitTypeDesc: [],
      user_list: [], // 用户的集合
      hitType: '',
      theQueryDialog: false,
      voiceName: [],
      readyToCopy: '',
      interface_id: [], // 拿到的接口类型
      amount_id: [
        {
          name: '新疆回拨',
          id: 74,
        },
      ], // 拿到的通信账户类型
      search_risk: '', //查询页面的风险等级,
      progressData: {},
      industry: {}, //报备行业
      textarea1641434070934: {}, //报备行业话术
    }
  },

  mounted() {
    this.valueTime = [getDate(0) + ' 00:00:00', getDate(0) + ' 23:59:59']
    this.getDataList(true)
    this.getVoiceName()
    this.getAmountType()
    industryQuery().then((res) => {
      let { code, data, message } = res
      if (code == 200) {
        this.industryList = data
      }
    })
  },
  methods: {
    // 滚动到指定质检关键字
    // 暂未实现同个关键字多处位置间的切换
    scrollTo(e) {
      this.$nextTick(() => {
        let list =
          e.currentTarget.parentElement.nextElementSibling.querySelectorAll(
            '.keyword-tag'
          )
        for (let i = 0; i < list.length; i++) {
          if (list[i].innerText === e.currentTarget.innerText) {
            list[i].scrollIntoView()
          }
        }
      })
    },
    // 将一条质检记录标记为已读(暂未刷新统计数)
    quickConfirm(row) {
      if (row.read == 1) {
        return
      }
      {
        voiceCheck
          .esCheck({
            id: row.id,
          })
          .then((res) => {
            if (res.code == 200) {
              row.read = 1
            }
          })
      }
    },
    //进度数据
    voiceCheckProgressChart() {
      voiceCheck
        .voiceCheckProgressChart({
          dateBegin: this.valueTime[0],
          dateEnd: this.valueTime[1],
        })
        .then((res) => {
          if (res.data) {
            let progressData = res.data.map((e) => {
              return {
                ...e,
                precent:
                  e.read && e.total ? parseInt((e.read / e.total) * 100) : 0,
              }
            })
            let non = progressData.find((e) => e.risk == 0)
            let low = progressData.find((e) => e.risk == 1)
            let mid = progressData.find((e) => e.risk == 2)
            let high = progressData.find((e) => e.risk == 3)
            // progressData.sort((e1,e2)=>e1.risk - e2.risk);
            this.progressData = {
              non: {
                ...(non || { precent: 0 }),
                format() {
                  return (
                    (non && non.read != null ? non.read : '--') +
                    '/' +
                    (non && non.total != null ? non.total : '--')
                  )
                },
              },
              low: {
                ...(low || { precent: 0 }),
                format() {
                  return (
                    (low && low.read != null ? low.read : '--') +
                    '/' +
                    (low && low.total != null ? low.total : '--')
                  )
                },
              },
              mid: {
                ...(mid || { precent: 0 }),
                format() {
                  return (
                    (mid && mid.read != null ? mid.read : '--') +
                    '/' +
                    (mid && mid.total != null ? mid.total : '--')
                  )
                },
              },
              high: {
                ...(high || { precent: 0 }),
                format() {
                  return (
                    (high && high.read != null ? high.read : '--') +
                    '/' +
                    (high && high.total != null ? high.total : '--')
                  )
                },
              },
            }
          }
        })
    },
    //下一个
    next() {
      const loading = this.$loading()
      voiceCheck
        .esCheckNext({
          createTimeBegin: this.valueTime[0],
          createTimeEnd: this.valueTime[1],
        })
        .then((res) => {
          loading.close()
          if (res.code == 200) {
            if (!res.data) {
              return this.$message.error('已经是最后一个了')
            }
            this.recordUrl = res.data.recordUrl
            this.hitType = res.data.hitType
            this.dialogueList = JSON.parse(
              res.data.translatedContent
            ).payload?.sentences
            this.rowJson = res.data
            this.rowJson.translatedContent = this.dialogueList
            if (this.rowJson.hitTypeDesc) {
              this.hitTypeDescList = this.rowJson.hitTypeDesc.split(',')
            }
            this.readyToCopy = `主叫：${
              res.data.telA || '--'
            }\r\n小号：${res.data.telX || '--'}\r\n被叫：${
              res.data.telB || '--'
            }\r\n通话时间：${res.data.stratTime || '--'}\r\n命中类型：${
              res.data.hitType || '--'
            }\r\n违规通知：检测到通话中出现违规词语，请贵公司在接到通知后2小时内核实并处理，如果未及时反馈给平台，平台将进行处罚\r\n录音地址：${
              res.data.recordUrl || '--'
            }`
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          loading.close()
        })
    },
    // 获取通信账户类型
    getAmountType() {
      // amountts.getAmountList({}).then((res) => {
      //   this.amount_id = res.data
      // })
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data
      })
    },
    // 根据后端返回的语音文本json,获取其中的句子部分,并高亮显示关键字
    getVoiceText(item) {
      let keywords = item.hitTypeDesc
      if (item.translatedContent == null) {
        return ['-']
      }
      let sentences = []
      try {
        sentences = JSON.parse(item.translatedContent)?.payload?.sentences
      } catch (err) {
        // 因查看对话会改变translatedContent值,这里先通过捕获异常处理
        sentences = item.translatedContent
      }
      if (keywords !== '' && keywords != null) {
        // 如果包含关键字,对关键字做高亮处理
        keywords.split(',').forEach((keyword) => {
          sentences.map((sentence) => {
            if (sentence.text.search(keyword) !== -1) {
              sentence.text = sentence.text.replace(
                keyword,
                '<b class="keyword-tag" style="color:#ff0000;">' +
                  keyword +
                  '</b>'
              )
              return sentence
            }
          })
        })
      }
      if (sentences == undefined || sentences.length === 0) {
        return ['-']
      }
      return sentences.map((sentence) => sentence.text)
    },
    // 选中服务商
    changeValuesamountId(val) {
      this.searchFrom.interfaceId = ''
      interfacets
        .getInterface({
          amountId: val,
        })
        .then((res) => {
          this.interface_id = res.data
        })
    },
    // 匹配服务商名
    amountName(amountId) {
      let amountTitle = ''
      if (amountId == null || amountId == '') {
        return '--'
      } else {
        this.amount_id.forEach((item, i) => {
          if (item.id == amountId) {
            amountTitle = item.name
          }
        })
        return amountTitle == '' ? '' : amountTitle
      }
    },
    //复制质检信息
    copyMessage() {
      /**
				   * 用户：xxxx
				    主叫：xxxxx
				    小号：xxxx
				    被叫：xxxx
				    命中关键词：xxxx
				    录音地址：http://123.com//
				   */
      let input = document.createElement('textarea')
      input.style.opacity = '0'
      input.style.position = 'absolute'
      input.style.zIndex = '-1'
      document.body.append(input)
      input.value = this.readyToCopy
      // console.log(this.readyToCopy)
      input.select()
      let result = document.execCommand('Copy')
      if (result) {
        ElMessage({
          message: '复制成功',
          type: 'success',
        })
      } else {
        ElMessage({
          message: '复制失败',
          type: 'error',
        })
      }
      setTimeout(function () {
        document.body.removeChild(input)
      }, 50)
    },
    // 查询
    onSubmit() {
      this.searchFrom.risk = this.search_risk
      this.getDataList(true)
      this.theQueryDialog = false
    },
    // 打开话单
    openCallList(row) {
      this.callDialogVisible = true
      bill
        .getByCallId({
          callId: row.callId,
        })
        .then((res) => {
          if (res.data == null) {
            this.callTableData = []
            this.callLoading = false
          } else {
            this.callTableData = [res.data]
            this.callLoading = false
          }
        })
    },
    changeValues(val) {
      this.searchFrom.durationBegin = this.durationToVal(val)[0]
      this.searchFrom.durationEnd = this.durationToVal(val)[1]
    },
    durationToVal(val) {
      if (val == 0) {
        return []
      }
      if (val == 1) {
        return [0, 10]
      }
      if (val == 2) {
        return [10, 60]
      }
      if (val == 3) {
        return [60, 90]
      }
      if (val == 4) {
        return [90, 120]
      }
      if (val == 5) {
        return [120, '']
      }
    },
    // 重置
    reset(kind) {
      // this.searchFrom = {
      //   page: this.currentPage,
      //   pageSize: 10,
      // }
      // this.durationVal = 0
      // this.search_risk = ''
      // this.valueTime = [getDate(0) + ' 00:00:00', getDate(0) + ' 23:59:59']
      if (kind == 1) {
        this.getDataList(true)
      }
    },
    // 语音质检类型下拉列表
    getVoiceName() {
      voiceType.voiceName({}).then((res) => {
        this.voiceName = res.data
      })
      // userts.queryList({}).then((res) => {
      //   this.user_list = res.data
      // })
    },
    // 封禁用户
    bannedUser(row) {
      this.$confirm('将封禁该号码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          voiceType
            .addBlackA({
              telA: row.telA,
              checkId: row.id,
            })
            .then((res) => {
              this.rowJson.telADisabled = 1
              this.$notify({
                title: '成功',
                message: '号码封禁成功！',
                type: 'success',
              })
              this.getDataList(true)
            })
        })
        .catch(() => {
          // this.$notify.info({
          //     title: '通知',
          //     message: '已取消删除!'
          // });
        })
    },
    // 封禁被叫
    bannedUserOther(row) {
      this.$confirm('将封禁该号码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          blackNumber
            .blackNumberAdd({
              blackNumberDtoList: [
                {
                  number: row.telB,
                  checkId: row.id,
                },
              ],
            })
            .then((res) => {
              this.rowJson.telBDisabled = 1
              this.$notify({
                title: '成功',
                message: '号码封禁成功！',
                type: 'success',
              })
              this.getDataList(true)
            })
        })
        .catch(() => {
          // this.$notify.info({
          //     title: '通知',
          //     message: '已取消删除!'
          // });
        })
    },
    // 获取数据列表
    getDataList(type) {
      this.voiceCheckProgressChart()
      this.loading = type
      // this.valueTime = ['2021-06-01 12:00:00','2021-07-31 08:00:00']
      if (this.valueTime != null) {
        this.searchFrom.createTimeBegin = this.valueTime[0]
        this.searchFrom.createTimeEnd = this.valueTime[1]
      } else {
        this.searchFrom.createTimeBegin = ''
        this.searchFrom.createTimeEnd = ''
      }
      voiceCheck
        .esAdminList({
          ...this.searchFrom,
        })
        .then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
          this.tableData.forEach((item) => {
            item.desc = this.getVoiceText(item)
          })
          // this.rowJson.telADisabled = res.data.records.telADisabled
          // this.rowJson.telBDisabled = res.data.records.telBDisabled
          this.loading = false
        })
    },
    // 分页
    handleSizeChange(val) {
      this.searchFrom.pageSize = val
      this.getDataList(true)
    },
    handleCurrentChange(val) {
      this.searchFrom.page = val
      this.currentPage = val
      this.getDataList(true)
    },
    // 折叠面板
    handleChange(val) {
      console.log(val)
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 批量删除
    deleteData() {
      console.log(this.multipleSelection)
    },
    // 打开对话
    openDiv(row) {
      voiceCheck
        .vosRead({
          id: row.id,
          // uid:this.$store.state.user.uid
        })
        .then((res) => {
          row.readInfor = true
        })
      this.readyToCopy = `主叫：${
        row.telA || '--'
      }\r\n小号：${row.telX || '--'}\r\n被叫：${
        row.telB || '--'
      }\r\n通话时间：${row.stratTime || '--'}\r\n命中类型：${
        row.hitType || '--'
      }\r\n违规通知：检测到通话中出现违规词语，请贵公司在接到通知后2小时内核实并处理，如果未及时反馈给平台，平台将进行处罚\r\n录音地址：${
        row.recordUrl || '--'
      }`
      if (this.readId.indexOf(row.id) == -1) {
        this.readId.push(row.id)
      }
      if (row.hitTypeDesc != null) {
        this.hitTypeDesc = row.hitTypeDesc.split(',')
      }
      voiceCheck
        .reportIndustry({
          mobile: row.telA,
          lineId: row.amountId,
        })
        .then((res) => {
          if (!res) {
            return
          } else {
            row.industry = res.industry //报备行业
            row.textarea1641434070934 = res.textarea1641434070934 //报备行业话术
          }
        })

      voiceCheck
        .getByCheckId({
          checkId: row.id,
        })
        .then((res) => {
          if (!res.data) {
            ElMessage({
              showClose: true,
              message: '暂无质检记录。',
              type: 'warning',
            })
          } else {
            this.recordUrl = row.recordUrl
            this.hitType = row.hitType
            this.dialogueList = JSON.parse(
              res.data.translatedContent
            ).payload?.sentences
            this.rowJson = row
            this.rowJson.translatedContent = this.dialogueList
            if (this.rowJson.hitTypeDesc) {
              this.hitTypeDescList = this.rowJson.hitTypeDesc.split(',')
            }
            this.centerDialogVisible = true
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 1.125rem;

  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }

  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}

.screening {
  margin-bottom: 0.9375rem;
}

.el-row {
  margin-bottom: 0.9375rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.risk-button ::v-deep(.el-radio-button__inner) {
  width: 80px;
}

.editor {
  color: #0170ed;
  background: #e3f0ff;
}

.center-body {
  margin: 0 1.875rem;
}

.deletetable {
  color: #ff845b;
  background: #ffe4db;
}

.deletetable-other {
  margin-right: 1.25rem;
}

.confirmAdd {
  background: #637dff;
  color: #fff;
}

.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}

.screening-out {
  margin: 0 0.9375rem;
}

.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}

.pager {
  display: flex;
  justify-content: flex-end;
}

.pass {
  color: #67c23a;
}

.unpass {
  color: #f56c6c;
}

.priceColor {
  color: #409eff;
}

.talks {
  cursor: pointer;
}

.record {
  height: 36px;
}

.dialogue {
  overflow-y: overlay;
  height: 25rem;
  padding: 0.425rem 1.5rem;
  background: #f5f5f5;
}

.recordSon {
  width: 100%;
  height: 30px;
}

.item-text {
  display: inline-block;
  padding: 0.375rem;
  border-radius: 0.375rem;
  color: #000;
}

.item-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.item-right {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.title-left {
  text-align: left;
}

.title-right {
  text-align: right;
}

.title-item {
  padding: 0.375rem;
}

.talkcolor {
  font-size: 1rem;
  color: #fff;
}

.talkcolorOther {
  font-size: 1rem;
  color: red;
}

.Read {
  background: #abadbb;
}
.riskText {
  width: 40px;
  height: 20px;
  line-height: 18px;
  border-radius: 15%;
  text-align: center;
}
.riskText-high {
  border: 1px solid #ff4949;
  color: #ff4949;
}
.riskText-middle {
  border: 1px solid #ffb352;
  color: #ffb352;
}
.riskText-low {
  border: 1px solid #eae556;
  color: #eae556;
}
.riskText-nothing {
  border: 1px solid #6be598;
  color: #6be598;
}
.showRemark {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
::v-deep(.el-progress-bar__innerText) {
  color: #000;
}
</style>

<style lang="scss">
.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}

.eltable {
  overflow-y: overlay;
}

.marigin-bottom-sm {
  margin-bottom: 5px;
}

.hitType-box-flex {
  display: flex;
  margin-bottom: 5px;
  align-items: center;

  .el-tag {
    margin-right: 5px;
  }
}
</style>
<style lang="scss" scoped>
.menu {
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.flex-nowrap-flex-start {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.flex-nowrap-space-between {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.mr-15 {
  margin-right: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-10 {
  margin-top: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.left-title {
  width: 120px;
  height: 45px;
  padding: 10px 0 10px 15px;
  background: #f5f6fa;
  color: #8085a4;
  border: 1px solid #ebebeb;
}
.left-text {
  width: 207px;
  height: 45px;
  color: #333333;
  padding: 10px 0 10px 15px;
  border: 1px solid #ebebeb;
  border-left: none;
}
.left-title-other {
  width: 120px;
  height: 185px;
  line-height: 185px;
  padding-left: 15px;
  background: #f5f6fa;
  color: #8085a4;
  border: 1px solid #ebebeb;
}
.left-text-other {
  width: 207px;
  height: 185px;
  padding: 15px;

  color: #333333;
  border: 1px solid #ebebeb;
  border-left: none;
}
.bottom-box {
  height: 45px;
  border: 1px solid #ebebeb;
}
.recordSon-audio {
  width: 95%;
  height: 30px;
}
.talkBox {
  padding: 10px;
  width: 100%;
  overflow-y: overlay;
  height: 720px;
  background: #f3f3f3;
}
.float-right {
  float: right;
}
.text-align-left {
  padding-left: 65px;
  text-align: left;
}
.text-align-right {
  padding-right: 65px;
  text-align: right;
}
.telA-box {
  padding: 8px;
  background: #fff;
  border-radius: 6px;
}
.telB-box {
  padding: 8px;
  background: #9eea6a;
  border-radius: 6px;
}
.relative-box {
  position: relative;
}
.triangle {
  position: absolute;
  transform: rotate(-90deg);
  top: 39%;
  left: 44px;
  border-style: solid;
  border-width: 0px 6px 10px 6px;
  opacity: 1.52;
  border-color: transparent transparent #ffff transparent;
  width: 0px;
  height: 0px;
}
.triangle-other {
  position: absolute;
  transform: rotate(90deg);
  top: 39%;
  right: 44px;
  border-style: solid;
  border-width: 0px 6px 10px 6px;
  opacity: 1.52;
  border-color: transparent transparent #9eea6a transparent;
  width: 0px;
  height: 0px;
}
.talkBox-item {
  padding: 2px 0;
}
.height-min {
  /* height: 500px; */
  overflow-y: overlay;
}
.right-width {
  margin-right: 10px;
  margin-bottom: 8px;
}
.overflow-y {
  overflow-y: overlay;
}
::v-deep(.el-table .cell) {
  line-height: 20px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipsis-line3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  cursor: pointer;
}
.voice-text {
  .voice-text-tooltip {
    font-size: 0.75rem;
  }
  .keywords {
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }
}
.sentence {
  background-color: #95ec69;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 7px;
  color: #000000;
}
</style>